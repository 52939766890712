var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view__container"},[_c('div',{staticClass:"content__top"},[_c('div',[_c('Breadcrumbs',{attrs:{"views":[
          { label: 'Correos', to: '/gestion-de-documentos/correos' },
          {
            label: 'Direcciones de correo',
            to: ("/gestion-de-documentos/correos/" + (_vm.$route.params.id)),
          } ],"currentView":{ label: 'Eventos' }}}),_c('h2',[_vm._v("Eventos")])],1),_c('div',{staticClass:"content__buttons"},[_c('Button',{attrs:{"type":"button","variant":"secondary","size":"small"},on:{"click":_vm.openExportModal}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"file-upload"}}),_vm._v(" Exportar ")],1)],1)]),_c('div',{staticClass:"content"},[_c('table',[_c('tbody',[_vm._m(0),(_vm.emailEvents.length && !_vm.isLoading)?_vm._l((_vm.emailEvents.sort(function (a, b) { return a.timestamp - b.timestamp; })),function(event,index){return _c('tr',{key:event.id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(event.event))]),_c('td',[_c('div',{staticClass:"log__date"},[_c('span',[_vm._v(" "+_vm._s(_vm.dateToDMY(_vm.timeStampToDate({ seconds: event.timestamp })))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.timeStampToDate({ seconds: event.timestamp }).toLocaleTimeString())+" ")])])]),_c('td',[_vm._v(_vm._s(event.browser || 'none'))])])}):_vm._e()],2)]),(_vm.isLoading)?_c('div',{staticClass:"loading"},[_c('loading-spinner')],1):_vm._e()]),_c('export-modal',{ref:"modal__export",attrs:{"formats":{
      excel: {
        id: 'excel',
        name: 'Excel',
        options: [{ id: 'mails', name: 'Eventos' }],
      },
    }},on:{"export":_vm.downloadExcel}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("Evento")]),_c('th',[_vm._v("Fecha")]),_c('th',[_vm._v("Navegador")])])}]

export { render, staticRenderFns }