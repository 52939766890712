<template>
  <div class="view__container">
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[
            { label: 'Correos', to: '/gestion-de-documentos/correos' },
            {
              label: 'Direcciones de correo',
              to: `/gestion-de-documentos/correos/${$route.params.id}`,
            },
          ]"
          :currentView="{ label: 'Eventos' }"
        />
        <h2>Eventos</h2>
      </div>
      <div class="content__buttons">
        <Button type="button" variant="secondary" size="small" @click="openExportModal">
          <unicon width="16px" height="16px" name="file-upload"></unicon>
          Exportar
        </Button>
      </div>
    </div>

    <div class="content">
      <table>
        <tbody>
          <tr>
            <th>#</th>
            <th>Evento</th>
            <th>Fecha</th>
            <th>Navegador</th>
          </tr>
          <template v-if="emailEvents.length && !isLoading">
            <tr
              v-for="(event, index) in emailEvents.sort((a, b) => a.timestamp - b.timestamp)"
              :key="event.id"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ event.event }}</td>
              <td>
                <div class="log__date">
                  <span>
                    {{ dateToDMY(timeStampToDate({ seconds: event.timestamp })) }}
                  </span>
                  <span>
                    {{ timeStampToDate({ seconds: event.timestamp }).toLocaleTimeString() }}
                  </span>
                </div>
              </td>
              <td>{{ event.browser || 'none' }}</td>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-if="isLoading" class="loading"><loading-spinner /></div>
    </div>

    <export-modal
      ref="modal__export"
      @export="downloadExcel"
      :formats="{
        excel: {
          id: 'excel',
          name: 'Excel',
          options: [{ id: 'mails', name: 'Eventos' }],
        },
      }"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import ExportModal from '@/components/ExportModal.vue';
import Button from '@/components/buttons/Button.vue';
import { timeStampToDate, dateToDMY } from '@/dateFormats';
import * as XLSX from 'xlsx';

export default {
  name: 'Events',
  components: {
    Breadcrumbs,
    LoadingSpinner,
    Button,
    ExportModal,
  },
  data() {
    return {
      isLoading: false,
      events: ['processed', 'delivered', 'open', 'bounce', 'dropped', 'spamreport', 'deferred'],
      timeStampToDate,
      dateToDMY,
    };
  },

  props: {},

  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions('emails', ['fetchEmails']),

    openExportModal() {
      this.$refs.modal__export.open();
    },

    downloadExcel() {
      const events = this.emailEvents
        .sort((a, b) => a.timestamp - b.timestamp)
        .map((event) => {
          const mapedEvent = {};
          mapedEvent.Evento = event.event;
          mapedEvent.Fecha = timeStampToDate({ seconds: event.timestamp });
          mapedEvent.Navegador = event.browser || 'none';
          return mapedEvent;
        });
      console.log(events);
      const worksheet = XLSX.utils.json_to_sheet(events);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Correos');
      XLSX.writeFile(workbook, 'reporte.xlsx');
    },
  },

  computed: {
    ...mapState({
      company: (state) => state.company,
      emails(state) {
        const emails = [];
        const eventsName = this.events.reduce((acc, curr) => {
          acc[curr] = 0;
          return acc;
        }, {});
        state.emails.emails.forEach((emailEl) => {
          const email = { ...emailEl, ...eventsName };
          const addresses = [];
          email.addresses.forEach((addressEl) => {
            const address = { ...addressEl, ...eventsName };
            const events = [];
            address.events.forEach((eventEl) => {
              const event = { ...eventEl };
              email[eventEl.event] += 1;
              address[eventEl.event] += 1;
              events.push(event);
            });
            address.events = events;
            addresses.push(address);
          });
          email.addresses = addresses;
          emails.push(email);
        });
        return emails;
      },
    }),

    emailEvents() {
      const temp = this.emails.find(({ id }) => id === this.$route.params.id);
      if (!temp || !temp.addresses.find((address) => address.id === this.$route.params.emailId)) {
        return [];
      }
      return this.emails
        .find(({ id }) => id === this.$route.params.id)
        .addresses.find((address) => address.id === this.$route.params.emailId).events;
    },
  },

  async mounted() {
    try {
      this.isLoading = true;
      if (this.emails.length === 0) await this.fetchEmails();

      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.view__container {
  border-radius: 10px;
  background-color: white;
  overflow: hidden;

  .content {
    overflow: auto;
  }

  .content__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .content__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }

  p {
    font-weight: var(--medium);
    color: var(--font-color-500);
    margin-bottom: 0.5rem;
  }

  label {
    cursor: pointer;
    /* Style as you please, it will become the visible UI component. */
  }

  table {
    width: 100%;
  }
}
.redirect a {
  cursor: pointer;
  color: var(--main-color-500);
  fill: var(--main-color-500);
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline var(--main-color-500);
  }
}
.loading {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.log__date {
  display: flex;
  flex-flow: column;

  span:nth-child(2) {
    font-size: 0.7rem;
    color: var(--font-color-100);
  }
}

th:first-child,
td:first-child {
  padding: 1rem;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
}
</style>
